:root,


body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}


.rounded-lg {
    border-radius: 1rem !important;
}

.text-small {
    font-size: 0.9rem !important;
}

.custom-separator {
    width: 5rem;
    height: 6px;
    border-radius: 1rem;
}

.text-uppercase {
    letter-spacing: 0.2em;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.style_border {
    border-color: black;
    border-style: solid;
}

.footer_Stick {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}


.center-content {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.feedback-color {
    background-color: white !important;
    margin-top: 2px !important;
}

.flex-center1 {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    margin-top: 100px;
    /*align-items: center;*/
}


.flex-center2 {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    margin-top: 200px;
    /*align-items: center;*/
}

/*select.unassigned {*/
/*    background-color:lightyellow !important;*/
/*}*/
/*.footer {*/
/*    position: absolute !important;*/
/*    bottom: 0 !important;*/
/*}*/
.form-green {
    border: 1px solid #47E10C !important;
}

.form-amber {
    border: 1px solid #FFBF00 !important;
}

.form-red {
    border: 1px solid #fd2727 !important;
}

.form-purple {
    border: 1px solid #8950FC !important;
}

.form-light-green {
    border: 1px solid #2cd1c9 !important;
}


/*  */
.form-green:focus {
    border: 1px solid #48e10c3d !important;
}

.form-amber:focus {
    border: 1px solid #ffbf008a !important;
}

.form-red:focus {
    border: 1px solid rgba(112, 3, 3, 0.527) !important;
}

.form-purple:focus {
    border: 1px solid rgba(113, 71, 197, 0.397)important;
}

.form-light-green:focus{
    border: 1px solid rgba(28, 134, 129, 0.534) !important;
}
/*  */

.form-control-color:focus {
    border: 1px solid #B5B5C3 !important;
}


/*.scroller{*/
/*    width: 110px;*/
/*    height: auto;*/
/*    overflow: scroll;*/
/*}*/
/* .Scroller::-webkit-scrollbar {
    width: 6px !important;
}

::-webkit-scrollbar-thumb:hover {
    width: 10px !important;
}

.Scroller::-webkit-scrollbar-track {
    background-color: red !important;
}

.Scroller::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}
/*  */


.Scroller::-webkit-scrollbar {
    width: 15px;
}

.Scroller::-webkit-scrollbar-thumb:hover {
    width: 50px;
}

.Scroller::-webkit-scrollbar-track {
    background-color: inherit;
}

::-webkit-scrollbar {
    width: 15px;
}

::-webkit-scrollbar-thumb {
    background: #5A5A5A !important;
    border-radius: 20px !important;
    border: 5px solid transparent !important;
    background-color: #4e4e4e;
    background-clip: content-box !important;
}



.Scroller {
    width: inherit;
    height: calc(100vh - 50px);
    overflow-y: scroll;
}

.Scroller::-webkit-scrollbar-thumb {
    background: #4e4e4e;
    border-radius: 25px;
    border: 5px solid transparent;
    background-color: #5A5A5A;
    background-clip: content-box;
}


/* .scroll-downs {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 34px;
    height: 55px;
} */

.mousey {
    width: 3px;
    padding: 12px 15px;
    height: 25px;
    border: 2px solid #fff;
    border-radius: 25px;
    opacity: 0.75;
    box-sizing: content-box;
}

.scroller_mouse {
    width: 3px;
    height: 10px;
    border-radius: 25%;
    background-color: #fff;
    animation-name: scroll;
    animation-duration: 2.2s;
    animation-timing-function: cubic-bezier(.15, .41, .69, .94);
    animation-iteration-count: infinite;
}

@keyframes scroll {
    0% {
        opacity: 0;
    }

    10% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(15px);
        opacity: 0;
    }
}


.input100:focus {
    color: #3f4254 !important;
    background-color: #fff !important;
    border-color: #3f4254 !important;
    outline: 0 !important
}


.formW:focus {
    background-color: white !important;
    border-color: #4c4b4b !important;
    outline: 0 !important;
    box-shadow: #323236;
}

.hrClass {
    margin-top: 20px !important;
    width: 50% !important;
    margin-left: auto !important;
    margin-right: auto !important;
}




@media (min-width: 992px) {
    .login.login-3 .login-aside {
        width: 100%;
        max-width: 600px;
    }

    .login.login-3 .login-aside .aside-img {
        min-height: 550px !important;
        background-size: 630px;
    }

    .login.login-3 .login-content .top-signup {
        max-width: 650px;
        width: 100%;
    }

    .login.login-3 .login-content .top-signin {
        max-width: 450px;
        width: 100%;
    }

    .login.login-3 .login-content .top-forgot {
        max-width: 450px;
        width: 100%;
    }

    .login.login-3 .login-content .login-form {
        width: 100%;
        max-width: 450px;
    }

    .login.login-3 .login-content .login-form.login-form-signup {
        max-width: 650px;
    }

    .PayRequest {
        max-width: 1000px;
    }
}

@media (min-width: 992px) and (max-width: 1399.98px) {
    .login.login-3 .login-aside {
        width: 100%;
        max-width: 400px;
    }

    .PayRequest {
        width: 100%;
        max-width: 800px;
    }
}

@media (max-width: 991.98px) {
    .PayRequest {
        width: 100%;
        max-width: 500px;
    }

    .login.login-3 .login-aside .aside-img {
        min-height: 500px !important;
        background-size: 500px;
    }

    .login.login-3 .login-aside .login-logo {
        text-align: center;
    }

    .login.login-3 .login-aside .wizard-nav {
        padding: 0;
        -ms-flex-line-pack: center;
        align-content: center;
    }

    .login.login-3 .login-aside .wizard-nav .wizard-steps .wizard-step {
        margin-bottom: 0.5rem;
    }

    .login.login-3 .login-aside .wizard-nav .wizard-steps .wizard-step:last-child {
        margin-bottom: 0;
    }

    .login.login-3 .login-content .top-signup {
        width: 100%;
        max-width: 400px;
    }

    .login.login-3 .login-content .top-signin {
        max-width: 400px;
        width: 100%;
    }

    .login.login-3 .login-content .top-forgot {
        max-width: 400px;
        width: 100%;
    }

    .login.login-3 .login-content .login-form {
        width: 100%;
        max-width: 400px;
    }
}


.mid {
    width: 50% !important;
    display: flex !important;
    align-content: center !important;
    justify-content: center !important;
    flex-wrap: wrap !important;
}

.flex-centernew {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    margin-top: 30px;
}


.maxWidth {
    margin-top: 25px;
    width: 95%;
}



.container_mine {
    /* position: absolute; */
    top: 50%;
    left: 50%;
    width: 40%;
    margin: auto;

    /* transform: translate(0%, 0%); */
}

.label_mine {
    padding: 10px;
    font-size: 18px;
    color: #111;
}

.copy-text {
    position: inherit;
    padding: 10px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    display: flex;
}

.copy-text input.text {
    padding: 10px;
    font-size: 18px;
    color: #555;
    border: none;
    outline: none;
}

.copy-text button {
    padding: 10px;
    background: #6A58FA;
    color: #fff;
    font-size: 18px;
    border: none;
    outline: none;
    border-radius: 10px;
    cursor: pointer;
}

.copy-text button:active {
    background: #6A58FA;
}

.copy-text button:before {
    content: "Copied";
    position: absolute;
    top: -45px;
    right: 0px;
    background: #6A58FA;
    padding: 8px 10px;
    border-radius: 20px;
    font-size: 15px;
    display: none;
}

.copy-text button:after {
    content: "";
    position: absolute;
    top: -20px;
    right: 25px;
    width: 10px;
    height: 10px;
    background: #6A58FA;
    transform: rotate(45deg);
    display: none;
}

.copy-text.active button:before,
.copy-text.active button:after {
    display: block;
}

.colorwhite {
    color: white !important;
}

.footer {
    position: fixed;
    height: 60px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
}

.stepper.stepper-pills .stepper-nav {
    display: flex;

}

.stepper.stepper-pills.stepper-column .stepper-nav {
    flex-direction: column;
    align-items: start;
}

.stepper.stepper-links .stepper-nav {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.stepper.stepper-links .stepper-nav .stepper-item {
    position: relative;
    flex-shrink: 0;
    margin: 1rem 1.5rem;
}

.stepper.stepper-links .stepper-nav .stepper-item:after {
    content: " ";
    position: absolute;
    top: 2.3rem;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: transparent;
    transition: color 0.2s ease;
}

.stepper.stepper-links .stepper-nav .stepper-item .stepper-title {
    color: var(--bs-dark);
    font-weight: 600;
    font-size: 1.25rem;
}

.stepper.stepper-links .stepper-nav .stepper-item.current {
    transition: color 0.2s ease;
}

.stepper.stepper-links .stepper-nav .stepper-item.current .stepper-title {
    color: #009ef7
}

.stepper.stepper-links .stepper-nav .stepper-item.current:after {
    background-color: #009ef7
}

.stepper.stepper-links .stepper-nav .stepper-item.current.mark-completed:last-child .stepper-title,
.stepper.stepper-links .stepper-nav .stepper-item.completed .stepper-title {
    color: var(--bs-gray-400);
}

.box-border-style {
    border-color: lightgrey !important;
}

.box-border-style:focus {
    border-color: darkgray !important;
}

.box-border-style_new:focus {
    background-color: #f3f6f9 !important;
}

@media (min-width: 992px) {
    .app-container {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    .app-container-fit-desktop {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

@media (max-width: 991.98px) {
    .app-container {
        max-width: none;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .app-container-fit-mobile {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

@media print {
    .app-print-content-only {
        padding: 0 !important;
        background: none !important;
    }

    .app-print-content-only .app-wrapper,
    .app-print-content-only .app-page,
    .app-print-content-only .app-page-title,
    .app-print-content-only .app-content,
    .app-print-content-only .app-container {
        background: none !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    .app-print-content-only .app-aside,
    .app-print-content-only .app-aside-panel,
    .app-print-content-only .app-sidebar,
    .app-print-content-only .app-sidebar-panel,
    .app-print-content-only .app-header,
    .app-print-content-only .app-footer,
    .app-print-content-only .app-toolbar,
    .app-print-content-only .drawer,
    .app-print-content-only .scrolltop,
    .app-print-content-only .btn {
        display: none !important;
    }
}

.link-color-new-P {
    color: #009ef7 !important
}


.btnP-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.btnP-check[disabled]+.btnP,
.btnP-check:disabled+.btnP {
    pointer-events: none;
    filter: none;
    opacity: 0.65;
}


.input-group .btnP {
    position: relative;
    z-index: 2;
}

.input-group .btnP:focus {
    z-index: 5;
}

.btnP {
    --bs-btnP-padding-x: 1.5rem;
    --bs-btnP-padding-y: 0.775rem;
    --bs-btnP-font-family: ;
    --bs-btnP-font-size: 1.1rem;
    --bs-btnP-font-weight: 500;
    --bs-btnP-line-height: 1.5;
    --bs-btnP-color: #181C32;
    --bs-btnP-bg: transparent;
    --bs-btnP-border-width: 1px;
    --bs-btnP-border-color: transparent;
    --bs-btnP-border-radius: 0.475rem;
    --bs-btnP-hover-border-color: transparent;
    --bs-btnP-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
    --bs-btnP-disabled-opacity: 0.65;
    --bs-btnP-focus-box-shadow: 0 0 0 0.25rem rgba(0, 134, 210, .5);
    display: inline-block;
    padding: var(--bs-btnP-padding-y) var(--bs-btnP-padding-x);
    font-family: var(--bs-btnP-font-family);
    font-size: var(--bs-btnP-font-size);
    font-weight: var(--bs-btnP-font-weight);
    line-height: var(--bs-btnP-line-height);
    color: var(--bs-btnP-color);
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border: var(--bs-btnP-border-width) solid var(--bs-btnP-border-color);
    border-radius: var(--bs-btnP-border-radius);
    background-color: var(--bs-btnP-bg);
    box-shadow: var(--bs-btnP-box-shadow);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


/* co */
@media (prefers-reduced-motion: reduce) {
    .btnP {
        transition: none;
    }
}

.btnP:hover {
    color: var(--bs-btnP-hover-color);
    background-color: var(--bs-btnP-hover-bg);
    border-color: var(--bs-btnP-hover-border-color);
}

.btnP-check+.btnP:hover {
    color: var(--bs-btnP-color);
    background-color: var(--bs-btnP-bg);
    border-color: var(--bs-btnP-border-color);
}

.btnP:focus-visible {
    color: var(--bs-btnP-hover-color);
    background-color: var(--bs-btnP-hover-bg);
    border-color: var(--bs-btnP-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btnP-box-shadow), var(--bs-btnP-focus-box-shadow);
}

.btnP-check:focus-visible+.btnP {
    border-color: var(--bs-btnP-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btnP-box-shadow), var(--bs-btnP-focus-box-shadow);
}

.btnP-check:checked+.btnP,
:not(.btnP-check)+.btnP:active,
.btnP:first-child:active,
.btnP.active,
.btnP.show {
    color: #000000;
    background-color: #73d7a1;
    border-color: #e4e6ec;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btnP-check:checked+.btnP:focus-visible,
:not(.btnP-check)+.btnP:active:focus-visible,
.btnP:first-child:active:focus-visible,
.btnP.active:focus-visible,
.btnP.show:focus-visible {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
}

.btnP:disabled,
.btnP.disabled,
fieldset:disabled .btnP {
    color: #000000;
    pointer-events: none;
    background-color: #009ef7;
    border-color: #F9F9F9;
    opacity: 0.65;
    box-shadow: none;
}

.btnP-light {
    --bs-btnP-color: #000000;
    --bs-btnP-bg: #F9F9F9;
    --bs-btnP-border-color: #F9F9F9;
    --bs-btnP-hover-color: #000000;
    --bs-btnP-hover-bg: #d4d4d4;
    --bs-btnP-hover-border-color: #c7c7c7;
    --bs-btnP-focus-shadow-rgb: 212, 212, 212;
    --bs-btnP-active-color: #000000;
    --bs-btnP-active-bg: #c7c7c7;
    --bs-btnP-active-border-color: #bbbbbb;
    --bs-btnP-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btnP-disabled-color: #000000;
    --bs-btnP-disabled-bg: #F9F9F9;
    --bs-btnP-disabled-border-color: #F9F9F9;
}

.btnP-primary {
    --bs-btnP-color: #000000;
    --bs-btnP-bg: #009ef7;
    --bs-btnP-border-color: #009ef7;
    --bs-btnP-hover-color: #000000;
    --bs-btnP-hover-bg: #26adf8;
    --bs-btnP-hover-border-color: #1aa8f8;
    --bs-btnP-focus-shadow-rgb: 0, 134, 210;
    --bs-btnP-active-color: #000000;
    --bs-btnP-active-bg: #33b1f9;
    --bs-btnP-active-border-color: #1aa8f8;
    --bs-btnP-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btnP-disabled-color: #000000;
    --bs-btnP-disabled-bg: #009ef7;
    --bs-btnP-disabled-border-color: #009ef7;
}

.btnP-secondary {
    --bs-btnP-color: #000000;
    --bs-btnP-bg: #E1E3EA;
    --bs-btnP-border-color: #E1E3EA;
    --bs-btnP-hover-color: #000000;
    --bs-btnP-hover-bg: #e6e7ed;
    --bs-btnP-hover-border-color: #e4e6ec;
    --bs-btnP-focus-shadow-rgb: 191, 193, 199;
    --bs-btnP-active-color: #000000;
    --bs-btnP-active-bg: #e7e9ee;
    --bs-btnP-active-border-color: #e4e6ec;
    --bs-btnP-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btnP-disabled-color: #000000;
    --bs-btnP-disabled-bg: #E1E3EA;
    --bs-btnP-disabled-border-color: #E1E3EA;
}

.btnP-success {
    --bs-btnP-color: #000000;
    --bs-btnP-bg: #50cd89;
    --bs-btnP-border-color: #50cd89;
    --bs-btnP-hover-color: #000000;
    --bs-btnP-hover-bg: #6ad59b;
    --bs-btnP-hover-border-color: #62d295;
    --bs-btnP-focus-shadow-rgb: 68, 174, 116;
    --bs-btnP-active-color: #000000;
    --bs-btnP-active-bg: #73d7a1;
    --bs-btnP-active-border-color: #62d295;
    --bs-btnP-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btnP-disabled-color: #000000;
    --bs-btnP-disabled-bg: #50cd89;
    --bs-btnP-disabled-border-color: #50cd89;
}

.btnP-info {
    --bs-btnP-color: #ffffff;
    --bs-btnP-bg: #7239ea;
    --bs-btnP-border-color: #7239ea;
    --bs-btnP-hover-color: #ffffff;
    --bs-btnP-hover-bg: #6130c7;
    --bs-btnP-hover-border-color: #5b2ebb;
    --bs-btnP-focus-shadow-rgb: 135, 87, 237;
    --bs-btnP-active-color: #ffffff;
    --bs-btnP-active-bg: #5b2ebb;
    --bs-btnP-active-border-color: #562bb0;
    --bs-btnP-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btnP-disabled-color: #ffffff;
    --bs-btnP-disabled-bg: #7239ea;
    --bs-btnP-disabled-border-color: #7239ea;
}

.btnP-warning {
    --bs-btnP-color: #000000;
    --bs-btnP-bg: #ffc700;
    --bs-btnP-border-color: #ffc700;
    --bs-btnP-hover-color: #000000;
    --bs-btnP-hover-bg: #ffcf26;
    --bs-btnP-hover-border-color: #ffcd1a;
    --bs-btnP-focus-shadow-rgb: 217, 169, 0;
    --bs-btnP-active-color: #000000;
    --bs-btnP-active-bg: #ffd233;
    --bs-btnP-active-border-color: #ffcd1a;
    --bs-btnP-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btnP-disabled-color: #000000;
    --bs-btnP-disabled-bg: #ffc700;
    --bs-btnP-disabled-border-color: #ffc700;
}

.btnP-danger {
    --bs-btnP-color: #000000;
    --bs-btnP-bg: #f1416c;
    --bs-btnP-border-color: #f1416c;
    --bs-btnP-hover-color: #000000;
    --bs-btnP-hover-bg: #f35e82;
    --bs-btnP-hover-border-color: #f2547b;
    --bs-btnP-focus-shadow-rgb: 205, 55, 92;
    --bs-btnP-active-color: #000000;
    --bs-btnP-active-bg: #f46789;
    --bs-btnP-active-border-color: #f2547b;
    --bs-btnP-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btnP-disabled-color: #000000;
    --bs-btnP-disabled-bg: #f1416c;
    --bs-btnP-disabled-border-color: #f1416c;
}

.btnP-dark {
    --bs-btnP-color: #ffffff;
    --bs-btnP-bg: #181C32;
    --bs-btnP-border-color: #181C32;
    --bs-btnP-hover-color: #ffffff;
    --bs-btnP-hover-bg: #3b3e51;
    --bs-btnP-hover-border-color: #2f3347;
    --bs-btnP-focus-shadow-rgb: 59, 62, 81;
    --bs-btnP-active-color: #ffffff;
    --bs-btnP-active-bg: #46495b;
    --bs-btnP-active-border-color: #2f3347;
    --bs-btnP-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btnP-disabled-color: #ffffff;
    --bs-btnP-disabled-bg: #181C32;
    --bs-btnP-disabled-border-color: #181C32;
}

.btnP-outline-light {
    --bs-btnP-color: #F9F9F9;
    --bs-btnP-border-color: #F9F9F9;
    --bs-btnP-hover-color: #000000;
    --bs-btnP-hover-bg: #F9F9F9;
    --bs-btnP-hover-border-color: #F9F9F9;
    --bs-btnP-focus-shadow-rgb: 249, 249, 249;
    --bs-btnP-active-color: #000000;
    --bs-btnP-active-bg: #F9F9F9;
    --bs-btnP-active-border-color: #F9F9F9;
    --bs-btnP-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btnP-disabled-color: #F9F9F9;
    --bs-btnP-disabled-bg: transparent;
    --bs-btnP-disabled-border-color: #F9F9F9;
    --bs-gradient: none;
}

.btnP-outline-primary {
    --bs-btnP-color: #009ef7;
    --bs-btnP-border-color: #009ef7;
    --bs-btnP-hover-color: #000000;
    --bs-btnP-hover-bg: #009ef7;
    --bs-btnP-hover-border-color: #009ef7;
    --bs-btnP-focus-shadow-rgb: 0, 158, 247;
    --bs-btnP-active-color: #000000;
    --bs-btnP-active-bg: #009ef7;
    --bs-btnP-active-border-color: #009ef7;
    --bs-btnP-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btnP-disabled-color: #009ef7;
    --bs-btnP-disabled-bg: transparent;
    --bs-btnP-disabled-border-color: #009ef7;
    --bs-gradient: none;
}

.btnP-outline-secondary {
    --bs-btnP-color: #E1E3EA;
    --bs-btnP-border-color: #E1E3EA;
    --bs-btnP-hover-color: #000000;
    --bs-btnP-hover-bg: #E1E3EA;
    --bs-btnP-hover-border-color: #E1E3EA;
    --bs-btnP-focus-shadow-rgb: 225, 227, 234;
    --bs-btnP-active-color: #000000;
    --bs-btnP-active-bg: #E1E3EA;
    --bs-btnP-active-border-color: #E1E3EA;
    --bs-btnP-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btnP-disabled-color: #E1E3EA;
    --bs-btnP-disabled-bg: transparent;
    --bs-btnP-disabled-border-color: #E1E3EA;
    --bs-gradient: none;
}

.btnP-outline-success {
    --bs-btnP-color: #50cd89;
    --bs-btnP-border-color: #50cd89;
    --bs-btnP-hover-color: #000000;
    --bs-btnP-hover-bg: #50cd89;
    --bs-btnP-hover-border-color: #50cd89;
    --bs-btnP-focus-shadow-rgb: 80, 205, 137;
    --bs-btnP-active-color: #000000;
    --bs-btnP-active-bg: #50cd89;
    --bs-btnP-active-border-color: #50cd89;
    --bs-btnP-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btnP-disabled-color: #50cd89;
    --bs-btnP-disabled-bg: transparent;
    --bs-btnP-disabled-border-color: #50cd89;
    --bs-gradient: none;
}

.btnP-outline-info {
    --bs-btnP-color: #7239ea;
    --bs-btnP-border-color: #7239ea;
    --bs-btnP-hover-color: #ffffff;
    --bs-btnP-hover-bg: #7239ea;
    --bs-btnP-hover-border-color: #7239ea;
    --bs-btnP-focus-shadow-rgb: 114, 57, 234;
    --bs-btnP-active-color: #ffffff;
    --bs-btnP-active-bg: #7239ea;
    --bs-btnP-active-border-color: #7239ea;
    --bs-btnP-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btnP-disabled-color: #7239ea;
    --bs-btnP-disabled-bg: transparent;
    --bs-btnP-disabled-border-color: #7239ea;
    --bs-gradient: none;
}

.btnP-outline-warning {
    --bs-btnP-color: #ffc700;
    --bs-btnP-border-color: #ffc700;
    --bs-btnP-hover-color: #000000;
    --bs-btnP-hover-bg: #ffc700;
    --bs-btnP-hover-border-color: #ffc700;
    --bs-btnP-focus-shadow-rgb: 255, 199, 0;
    --bs-btnP-active-color: #000000;
    --bs-btnP-active-bg: #ffc700;
    --bs-btnP-active-border-color: #ffc700;
    --bs-btnP-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btnP-disabled-color: #ffc700;
    --bs-btnP-disabled-bg: transparent;
    --bs-btnP-disabled-border-color: #ffc700;
    --bs-gradient: none;
}

.btnP-outline-danger {
    --bs-btnP-color: #f1416c;
    --bs-btnP-border-color: #f1416c;
    --bs-btnP-hover-color: #000000;
    --bs-btnP-hover-bg: #f1416c;
    --bs-btnP-hover-border-color: #f1416c;
    --bs-btnP-focus-shadow-rgb: 241, 65, 108;
    --bs-btnP-active-color: #000000;
    --bs-btnP-active-bg: #f1416c;
    --bs-btnP-active-border-color: #f1416c;
    --bs-btnP-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btnP-disabled-color: #f1416c;
    --bs-btnP-disabled-bg: transparent;
    --bs-btnP-disabled-border-color: #f1416c;
    --bs-gradient: none;
}

.btnP-outline-dark {
    --bs-btnP-color: #181C32;
    --bs-btnP-border-color: #181C32;
    --bs-btnP-hover-color: #ffffff;
    --bs-btnP-hover-bg: #181C32;
    --bs-btnP-hover-border-color: #181C32;
    --bs-btnP-focus-shadow-rgb: 24, 28, 50;
    --bs-btnP-active-color: #ffffff;
    --bs-btnP-active-bg: #181C32;
    --bs-btnP-active-border-color: #181C32;
    --bs-btnP-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btnP-disabled-color: #181C32;
    --bs-btnP-disabled-bg: transparent;
    --bs-btnP-disabled-border-color: #181C32;
    --bs-gradient: none;
}

.btnP-link {
    --bs-btnP-font-weight: 400;
    --bs-btnP-color: #009ef7;
    --bs-btnP-bg: transparent;
    --bs-btnP-border-color: transparent;
    --bs-btnP-hover-color: #0095e8;
    --bs-btnP-hover-border-color: transparent;
    --bs-btnP-active-color: #0095e8;
    --bs-btnP-active-border-color: transparent;
    --bs-btnP-disabled-color: #7E8299;
    --bs-btnP-disabled-border-color: transparent;
    --bs-btnP-box-shadow: none;
    --bs-btnP-focus-shadow-rgb: 0, 134, 210;
    text-decoration: none;
}

.btnP-link:hover,
.btnP-link:focus-visible {
    text-decoration: none;
}

.btnP-link:focus-visible {
    color: #000000
}

.btnP-link:hover {
    color: #000000;
}

.btnP-lg,
.btnP-group-lg>.btnP {
    --bs-btnP-padding-y: 0.825rem;
    --bs-btnP-padding-x: 1.75rem;
    --bs-btnP-font-size: 1.15rem;
    --bs-btnP-border-radius: 0.625rem;
}

.btnP-sm,
.btnP-group-sm>.btnP {
    --bs-btnP-padding-y: 0.55rem;
    --bs-btnP-padding-x: 1.25rem;
    --bs-btnP-font-size: 0.95rem;
    --bs-btnP-border-radius: 0.425rem;
}


.btnP-group,
.btnP-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
}

.btnP-group>.btnP,
.btnP-group-vertical>.btnP {
    position: relative;
    flex: 1 1 auto;
}

.btnP-group>.btnP-check:checked+.btnP,
.btnP-group>.btnP-check:focus+.btnP,
.btnP-group>.btnP:hover,
.btnP-group>.btnP:focus,
.btnP-group>.btnP:active,
.btnP-group>.btnP.active,
.btnP-group-vertical>.btnP-check:checked+.btnP,
.btnP-group-vertical>.btnP-check:focus+.btnP,
.btnP-group-vertical>.btnP:hover,
.btnP-group-vertical>.btnP:focus,
.btnP-group-vertical>.btnP:active,
.btnP-group-vertical>.btnP.active {
    z-index: 1;
}

.btnP-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.btnP-toolbar .input-group {
    width: auto;
}

.btnP-group {
    border-radius: 0.475rem;
}

.btnP-group> :not(.btnP-check:first-child)+.btnP,
.btnP-group>.btnP-group:not(:first-child) {
    margin-left: -1px;
}

.btnP-group>.btnP:not(:last-child):not(.dropdown-toggle),
.btnP-group>.btnP.dropdown-toggle-split:first-child,
.btnP-group>.btnP-group:not(:last-child)>.btnP {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btnP-group>.btnP:nth-child(n+3),
.btnP-group> :not(.btnP-check)+.btnP,
.btnP-group>.btnP-group:not(:first-child)>.btnP {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}


.btnP-sm+.dropdown-toggle-split,
.btnP-group-sm>.btnP+.dropdown-toggle-split {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
}

.btnP-lg+.dropdown-toggle-split,
.btnP-group-lg>.btnP+.dropdown-toggle-split {
    padding-right: 1.3125rem;
    padding-left: 1.3125rem;
}

.btnP-group.show .dropdown-toggle {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btnP-group.show .dropdown-toggle.btnP-link {
    box-shadow: none;
}

.btnP-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.btnP-group-vertical>.btnP,
.btnP-group-vertical>.btnP-group {
    width: 100%;
}

.btnP-group-vertical>.btnP:not(:first-child),
.btnP-group-vertical>.btnP-group:not(:first-child) {
    margin-top: -1px;
}

.btnP-group-vertical>.btnP:not(:last-child):not(.dropdown-toggle),
.btnP-group-vertical>.btnP-group:not(:last-child)>.btnP {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btnP-group-vertical>.btnP~.btnP,
.btnP-group-vertical>.btnP-group:not(:first-child)>.btnP {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}



.btnP .badge {
    position: relative;
    top: -1px;
}


.btnP.btnP-outline:not(.btnP-outline-dashed) {
    border: 1px solid #E1E3EA;
}

.btnP.btnP-outline-dashed {
    border: 1px dashed #E1E3EA;
}

.btnP.btnP-outline.btnP-outline-dashed {
    border-width: 1px;
    border-style: dashed;
}

.btnP-check:checked+.btnP.btnP-outline.btnP-outline-dashed,
.btnP-check:active+.btnP.btnP-outline.btnP-outline-dashed,
.btnP.btnP-outline.btnP-outline-dashed:focus:not(.btnP-active),
.btnP.btnP-outline.btnP-outline-dashed:hover:not(.btnP-active),
.btnP.btnP-outline.btnP-outline-dashed:active:not(.btnP-active),
.btnP.btnP-outline.btnP-outline-dashed.active,
.btnP.btnP-outline.btnP-outline-dashed.show,
.show>.btnP.btnP-outline.btnP-outline-dashed {
    border-color: #009ef7;
}

.btnP.btnP-color-dark {
    color: #181C32
}

.btnP.btnP-color-dark i,
.btnP.btnP-color-dark .svg-icon {
    color: #181C32
}

.btnP.btnP-color-dark.dropdown-toggle:after {
    color: #181C32
}


.btnP-check:checked+.btnP.btnP-active-primary,
.btnP-check:active+.btnP.btnP-active-primary,
.btnP.btnP-active-primary:focus:not(.btnP-active),
.btnP.btnP-active-primary:hover:not(.btnP-active),
.btnP.btnP-active-primary:active:not(.btnP-active),
.btnP.btnP-active-primary.active,
.btnP.btnP-active-primary.show,
.show>.btnP.btnP-active-primary {
    color: #ffffff !important;
    border-color: #009ef7 !important;
    background-color: #009ef7 !important;
}

.btnP-check:checked+.btnP.btnP-active-primary i,
.btnP-check:checked+.btnP.btnP-active-primary .svg-icon,
.btnP-check:active+.btnP.btnP-active-primary i,
.btnP-check:active+.btnP.btnP-active-primary .svg-icon,
.btnP.btnP-active-primary:focus:not(.btnP-active) i,
.btnP.btnP-active-primary:focus:not(.btnP-active) .svg-icon,
.btnP.btnP-active-primary:hover:not(.btnP-active) i,
.btnP.btnP-active-primary:hover:not(.btnP-active) .svg-icon,
.btnP.btnP-active-primary:active:not(.btnP-active) i,
.btnP.btnP-active-primary:active:not(.btnP-active) .svg-icon,
.btnP.btnP-active-primary.active i,
.btnP.btnP-active-primary.active .svg-icon,
.btnP.btnP-active-primary.show i,
.btnP.btnP-active-primary.show .svg-icon,
.show>.btnP.btnP-active-primary i,
.show>.btnP.btnP-active-primary .svg-icon {
    color: #ffffff !important;
}

.btnP-check:checked+.btnP.btnP-active-primary.dropdown-toggle:after,
.btnP-check:active+.btnP.btnP-active-primary.dropdown-toggle:after,
.btnP.btnP-active-primary:focus:not(.btnP-active).dropdown-toggle:after,
.btnP.btnP-active-primary:hover:not(.btnP-active).dropdown-toggle:after,
.btnP.btnP-active-primary:active:not(.btnP-active).dropdown-toggle:after,
.btnP.btnP-active-primary.active.dropdown-toggle:after,
.btnP.btnP-active-primary.show.dropdown-toggle:after,
.show>.btnP.btnP-active-primary.dropdown-toggle:after {
    color: #ffffff !important;
}

.ms-5 {
    margin-left: 1.25rem !important;
}

.fs-3x {
    font-size: 3rem !important;
}

.fw-bold {
    font-weight: 600 !important;
}

.fs-7 {
    font-size: 0.95rem !important;
}

.opacity-50 {
    opacity: 0.5 !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.fs-2 {
    font-size: 1px !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.fw-semibold {
    font-weight: 500 !important;
}

.opacity-75 {
    opacity: 0.75 !important;
}

.flex-stack {
    justify-content: space-between;
    align-items: center;
}

.form-check.form-check-success .form-check-input:checked {
    background-color: #50cd89 !important;
}

.badge-light-success {
    color: #50cd89 !important;
    background-color: #e8fff3 !important;
}


.btn:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg):not(.shadow-xs) {
    box-shadow: none;
}

.btn-check:checked+.btn.btn-outline.btn-outline-dashed,
.btn-check:active+.btn.btn-outline.btn-outline-dashed,
.btn.btn-outline.btn-outline-dashed:focus:not(.btn-active),
.btn.btn-outline.btn-outline-dashed:hover:not(.btn-active),
.btn.btn-outline.btn-outline-dashed:active:not(.btn-active),
.btn.btn-outline.btn-outline-dashed.active,
.btn.btn-outline.btn-outline-dashed.show,
.show>.btn.btn-outline.btn-outline-dashed {
    border-color: #009ef7 !important
}

.btn-check:checked+.btn.btn-active-light-primary,
.btn-check:active+.btn.btn-active-light-primary,
.btn.btn-active-light-primary:focus:not(.btn-active),
.btn.btn-active-light-primary:hover:not(.btn-active),
.btn.btn-active-light-primary:active:not(.btn-active),
.btn.btn-active-light-primary.active,
.btn.btn-active-light-primary.show,
.show>.btn.btn-active-light-primary {
    color: #009ef7;
    background-color: #f1faff;
}

.btn.btn-outline.btn-outline-dashed {
    border-width: 1px;
    border-style: dashed;
}

.btn.btn-outline-dashed {
    border: 1px dashed #e1e3ea
}

.btn-outline-warning {
    --bs-btn-color: #ffc700;
    --bs-btn-border-color: #ffc700;
    --bs-btn-hover-color: #000000;
    --bs-btn-hover-bg: #ffc700;
    --bs-btn-hover-border-color: #ffc700;
    --bs-btn-focus-shadow-rgb: 255, 199, 0;
    --bs-btn-active-color: #000000;
    --bs-btn-active-bg: #ffc700;
    --bs-btn-active-border-color: #ffc700;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffc700;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #ffc700;
    --bs-gradient: none;
}



.btn.btn-outline.btn-outline-warning {
    color: #ffc700 !important;
    border-color: #ffc700;
    background-color: transparent;
}

.btn.btn-outline.btn-outline-warning i,
.btn.btn-outline.btn-outline-warning .svg-icon {
    color: #ffc700;
}

.btn.btn-outline.btn-outline-warning.dropdown-toggle:after {
    color: #ffc700;
}

.btn-check:checked+.btn.btn-outline.btn-outline-warning,
.btn-check:active+.btn.btn-outline.btn-outline-warning,
.btn.btn-outline.btn-outline-warning:focus:not(.btn-active),
.btn.btn-outline.btn-outline-warning:hover:not(.btn-active),
.btn.btn-outline.btn-outline-warning:active:not(.btn-active),
.btn.btn-outline.btn-outline-warning.active,
.btn.btn-outline.btn-outline-warning.show,
.show>.btn.btn-outline.btn-outline-warning {
    color: #f1bc00;
    border-color: #ffc700;
    background-color: #fff8dd !important;
}

.btn-check:checked+.btn.btn-outline.btn-outline-warning i,
.btn-check:checked+.btn.btn-outline.btn-outline-warning .svg-icon,
.btn-check:active+.btn.btn-outline.btn-outline-warning i,
.btn-check:active+.btn.btn-outline.btn-outline-warning .svg-icon,
.btn.btn-outline.btn-outline-warning:focus:not(.btn-active) i,
.btn.btn-outline.btn-outline-warning:focus:not(.btn-active) .svg-icon,
.btn.btn-outline.btn-outline-warning:hover:not(.btn-active) i,
.btn.btn-outline.btn-outline-warning:hover:not(.btn-active) .svg-icon,
.btn.btn-outline.btn-outline-warning:active:not(.btn-active) i,
.btn.btn-outline.btn-outline-warning:active:not(.btn-active) .svg-icon,
.btn.btn-outline.btn-outline-warning.active i,
.btn.btn-outline.btn-outline-warning.active .svg-icon,
.btn.btn-outline.btn-outline-warning.show i,
.btn.btn-outline.btn-outline-warning.show .svg-icon,
.show>.btn.btn-outline.btn-outline-warning i,
.show>.btn.btn-outline.btn-outline-warning .svg-icon {
    color: #f1bc00;
}

.btn-check:checked+.btn.btn-outline.btn-outline-warning.dropdown-toggle:after,
.btn-check:active+.btn.btn-outline.btn-outline-warning.dropdown-toggle:after,
.btn.btn-outline.btn-outline-warning:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-outline.btn-outline-warning:hover:not(.btn-active).dropdown-toggle:after,
.btn.btn-outline.btn-outline-warning:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-outline.btn-outline-warning.active.dropdown-toggle:after,
.btn.btn-outline.btn-outline-warning.show.dropdown-toggle:after,
.show>.btn.btn-outline.btn-outline-warning.dropdown-toggle:after {
    color: #f1bc00;
}


@media (min-width: 992px) {
    .p-lg-20 {
        padding: 5rem !important;
    }
}


.flex-column {
    flex-direction: column !important;
}

.flex-xl-row {
    flex-direction: row !important;
}

.flex-lg-row-fluid {
    flex: 1 auto;
    min-width: 0;
}

.me-xl-18 {
    margin-right: 4.5rem !important;
}

.mb-xl-0 {
    margin-bottom: 0 !important;
}

.mt-n1 {
    margin-top: -0.25rem !important;
}

.pb-10 {
    padding-bottom: 2.5rem !important;
}


.btn-sm,
.btn-group-sm>.btn {
    --bs-btn-padding-y: 0.55rem;
    --bs-btn-padding-x: 1.25rem;
    --bs-btn-font-size: 0.95rem;
    --bs-btn-border-radius: 0.425rem;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
}

.btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon).btn-sm,
.btn-group-sm>.btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    padding: calc(0.55rem + 1px) calc(1.25rem + 1px);
}

.btn.btn-icon.btn-sm,
.btn-group-sm>.btn.btn-icon {
    height: calc(1.5em + 1.1rem + 2px);
    width: calc(1.5em + 1.1rem + 2px);
}



.btn-success {
    --bs-btn-color: #000000;
    --bs-btn-bg: #50cd89;
    --bs-btn-border-color: #50cd89;
    --bs-btn-hover-color: #000000;
    --bs-btn-hover-bg: #6ad59b;
    --bs-btn-hover-border-color: #62d295;
    --bs-btn-focus-shadow-rgb: 68, 174, 116;
    --bs-btn-active-color: #000000;
    --bs-btn-active-bg: #73d7a1;
    --bs-btn-active-border-color: #62d295;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000000;
    --bs-btn-disabled-bg: #50cd89;
    --bs-btn-disabled-border-color: #50cd89;
}


.btn.btn-success {
    color: #ffffff;
    border-color: #50cd89;
    background-color: #50cd89;
}

.btn.btn-success i,
.btn.btn-success .svg-icon {
    color: #ffffff;
}

.btn.btn-success.dropdown-toggle:after {
    color: #ffffff;
}

.btn-check:checked+.btn.btn-success,
.btn-check:active+.btn.btn-success,
.btn.btn-success:focus:not(.btn-active),
.btn.btn-success:hover:not(.btn-active),
.btn.btn-success:active:not(.btn-active),
.btn.btn-success.active,
.btn.btn-success.show,
.show>.btn.btn-success {
    color: #ffffff;
    border-color: #47be7d;
    background-color: #47be7d !important;
}

.btn-check:checked+.btn.btn-success i,
.btn-check:checked+.btn.btn-success .svg-icon,
.btn-check:active+.btn.btn-success i,
.btn-check:active+.btn.btn-success .svg-icon,
.btn.btn-success:focus:not(.btn-active) i,
.btn.btn-success:focus:not(.btn-active) .svg-icon,
.btn.btn-success:hover:not(.btn-active) i,
.btn.btn-success:hover:not(.btn-active) .svg-icon,
.btn.btn-success:active:not(.btn-active) i,
.btn.btn-success:active:not(.btn-active) .svg-icon,
.btn.btn-success.active i,
.btn.btn-success.active .svg-icon,
.btn.btn-success.show i,
.btn.btn-success.show .svg-icon,
.show>.btn.btn-success i,
.show>.btn.btn-success .svg-icon {
    color: #ffffff;
}

.btn-check:checked+.btn.btn-success.dropdown-toggle:after,
.btn-check:active+.btn.btn-success.dropdown-toggle:after,
.btn.btn-success:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-success:hover:not(.btn-active).dropdown-toggle:after,
.btn.btn-success:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-success.active.dropdown-toggle:after,
.btn.btn-success.show.dropdown-toggle:after,
.show>.btn.btn-success.dropdown-toggle:after {
    color: #ffffff;
}

.fs-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
}


.text-gray-800 {
    color: #3F4254 !important;
}

.g-5,
.gx-5 {
    --bs-gutter-x: 1.25rem;
}

.fs-6 {
    font-size: 1.075rem !important;
}

.text-gray-600 {
    color: #7E8299 !important;
}


.d-flex {
    display: flex !important;
}

.pe-2 {
    padding-right: 0.5rem !important;
}

.text-danger {
    --bs-text-opacity: 1;
    color: rgba(241, 65, 108, 1) !important;
}

.text-danger-emphasis {
    color: #b02a37 !important;
}

.text-danger {
    color: #f1416c !important;
}

.align-items-center {
    align-items: center !important;
}

.bullet-dot {
    width: 4px;
    height: 4px;
    border-radius: 100% !important;
}

.bg-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(241, 65, 108, var(--bs-bg-opacity)) !important;
}

.bg-danger-subtle {
    background-color: #f8d7da !important;
}

.bg-danger {
    --bs-bg-rgb-color: 241, 65, 108;
    background-color: #f1416c !important;
}

.bg-danger-active {
    --bs-bg-rgb-color: var(--bs-danger-active-rgb);
    background-color: var(--bs-danger-active) !important;
}

.me-2 {
    margin-right: 0.5rem !important;
}


.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@media (max-width: 1399.98px) {
    .table-responsive-xxl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

.border-bottom {
    border-bottom: 1px solid #F4F4F4 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.text-muted {
    --bs-text-opacity: 1;
    color: #A1A5B7 !important;
}

.min-w-175px {
    min-width: 175px !important;
}

.min-w-70px {
    min-width: 70px !important;
}

.min-w-80px {
    min-width: 80px !important;
}

.min-w-100px {
    min-width: 100px !important;
}

.text-end {
    text-align: right !important;
}

.text-gray-700 {
    color: #5E6278 !important
}


.fs-5 {
    font-size: 1.15rem !important;
}

.fw-bolder {
    font-weight: 700 !important;
}


.justify-content-end {
    justify-content: flex-end !important;
}

.mw-300px {
    max-width: 300px !important;
}

.pe-10 {
    padding-right: 2.5rem !important;
}


/* .d-print-none {
    display: none !important;
  } */


.border {
    border: 1px solid #F4F4F4 !important;
}

.border-dashed {
    border-style: dashed !important;
    border-color: #E1E3EA;
}

.border-gray-300 {
    border-color: #E1E3EA !important;
}


.card-rounded {
    border-radius: 0.625rem;
}

.card-rounded-start {
    border-top-left-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
}

.card-rounded-end {
    border-top-right-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
}

.card-rounded-top {
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
}

.card-rounded-bottom {
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
}

.h-lg-100 {
    height: 100% !important;
}

.min-w-md-350px {
    min-width: 350px !important;
}

.p-9 {
    padding: 2.25rem !important;
}

.badge-light-warning {
    color: #ffc700 !important;
    background-color: #fff8dd !important;
}

.text-hover-primary {
    transition: color 0.2s ease;
}

.text-hover-primary i {
    transition: color 0.2s ease;
}

.text-hover-primary:hover {
    transition: color 0.2s ease;
    color: #009ef7 !important;
}

.text-hover-primary:hover i {
    transition: color 0.2s ease;
    color: #009ef7 !important;
}

.text-hover-primary:hover .svg-icon {
    color: #009ef7 !important;
}

.EmailDownload {
    background-color: rgb(213, 217, 226) !important;
    --kt-scrollbar-color: #d9d0cc !important;
    --kt-scrollbar-hover-color: #d9d0cc !important;
    height: auto !important;
}


ul li.menu-item:hover {
    background-color: #1b1b28 !important;
}

.symbol-light-success {
    background-color: #C9F7F5;
    color: #1BC5BD;
}


.FreezeTable th {
    z-index: 2;
}

.FreezeTable th:first-child,
.FreezeTable td:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: white;
}

.FreezeTable th:nth-child(2),
.FreezeTable td:nth-child(2) {
    position: sticky;
    left: 80px;
    z-index: 2;
    background-color: white;
}

/* 
.FreezeTable th:first-child,
.FreezeTable :nth-child(2) {
    z-index: 2;
}
 */

.headcol2 {
    position: absolute;
    width: 5em;
    right: 0;
    top: auto;
    border-top-width: 0;
    background-color: inherit !important;
    border: inherit !important;
}




.ScrollerSmall::-webkit-scrollbar {
    width: 15px;
}

.ScrollerSmall::-webkit-scrollbar-thumb:hover {
    width: 50px;
}

.ScrollerSmall::-webkit-scrollbar-track {
    background-color: inherit;
}


.ScrollerSmall {
    width: inherit;
    height: calc(30vh - 50px);
    overflow-y: scroll;
}

.ScrollerSmall::-webkit-scrollbar-thumb {
    background: #4e4e4e;
    border-radius: 25px;
    border: 5px solid transparent;
    background-color: #5A5A5A;
    background-clip: content-box;
}

.tableFixHead>table {
    border-collapse: collapse;
    width: 100%;
}

.tableFixHead>th,
.tableFixHead>td {
    background: #fff;
    padding: 8px 16px;
}


.tableFixHead {
    overflow: inherit;
    height: calc(60vh - 50px);
}

.tableFixHead thead th {
    position: sticky;
    top: 0;
}

.tableFixHead::-webkit-scrollbar {
    width: 15px;
}

.tableFixHead::-webkit-scrollbar-thumb:hover {
    width: 50px;
}

.tableFixHead::-webkit-scrollbar-track {
    background-color: inherit;
}

.tableFixHead::-webkit-scrollbar-thumb {
    background: #4e4e4e;
    border-radius: 25px;
    border: 5px solid transparent;
    background-color: #5A5A5A;
    background-clip: content-box;
}

.red-highlight {
    border-color: #F64E60 !important
}

.blackcolor {
    color: #5A5A5A !important;
}

.cursor-pointer:hover {
    cursor: pointer !important;
}